import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import {Link} from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";

const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
);

// const studentsData = [
//   {
//     id: 1,
//     kelas: "XII IPA 1",
//     rombel: "A",
//     nisn: "1234567890",
//     nama_siswa: "Ahmad Setiawan",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Bu Ratna",
//     no_peserta_ujian: "20230123401",
//     token: "ABC123XYZ",
//     status: "online"
//   },
//   {
//     id: 2,
//     kelas: "XII IPA 1",
//     rombel: "B",
//     nisn: "0987654321",
//     nama_siswa: "Siti Aminah",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Pak Budi",
//     no_peserta_ujian: "20230123402",
//     token: "XYZ123ABC",
//     status: "offline"
//   },
//   {
//     id: 3,
//     kelas: "XII IPA 2",
//     rombel: "A",
//     nisn: "1122334455",
//     nama_siswa: "Rudi Santoso",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Bu Sari",
//     no_peserta_ujian: "20230123403",
//     token: "DEF456GHI",
//     status: "soal terkirim"
//   },
//   {
//     id: 4,
//     kelas: "XII IPA 2",
//     rombel: "B",
//     nisn: "3344556677",
//     nama_siswa: "Nina Lestari",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Pak Dedi",
//     no_peserta_ujian: "20230123404",
//     token: "GHI789JKL",
//     status: "mengerjakan"
//   },
//   {
//     id: 5,
//     kelas: "XII IPS 1",
//     rombel: "A",
//     nisn: "4455667788",
//     nama_siswa: "Budi Setiawan",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Bu Siti",
//     no_peserta_ujian: "20230123405",
//     token: "JKL012MNO",
//     status: "selesai"
//   },
//   {
//     id: 6,
//     kelas: "XII IPS 1",
//     rombel: "B",
//     nisn: "5566778899",
//     nama_siswa: "Sari Dewi",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Pak Joko",
//     no_peserta_ujian: "20230123406",
//     token: "MNO345PQR",
//     status: "offline"
//   },
//   {
//     id: 7,
//     kelas: "XII IPS 2",
//     rombel: "A",
//     nisn: "6677889900",
//     nama_siswa: "Rina Ayu",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Bu Erna",
//     no_peserta_ujian: "20230123407",
//     token: "PQR678STU",
//     status: "online"
//   },
//   {
//     id: 8,
//     kelas: "XII IPS 2",
//     rombel: "B",
//     nisn: "7788990011",
//     nama_siswa: "Arif Hidayat",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Pak Deni",
//     no_peserta_ujian: "20230123408",
//     token: "STU901VWX",
//     status: "soal terkirim"
//   },
//   {
//     id: 9,
//     kelas: "XII IPA 3",
//     rombel: "A",
//     nisn: "8899001122",
//     nama_siswa: "Dewi Sartika",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Bu Maria",
//     no_peserta_ujian: "20230123409",
//     token: "VWX234YZA",
//     status: "mengerjakan"
//   },
//   {
//     id: 10,
//     kelas: "XII IPA 3",
//     rombel: "B",
//     nisn: "9900112233",
//     nama_siswa: "Bambang Susilo",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Pak Rudi",
//     no_peserta_ujian: "20230123410",
//     token: "YZA567BCD",
//     status: "selesai"
//   },
//   {
//     id: 11,
//     kelas: "XII IPA 4",
//     rombel: "A",
//     nisn: "0011223344",
//     nama_siswa: "Sri Astuti",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Bu Dina",
//     no_peserta_ujian: "20230123411",
//     token: "BCD890EFG",
//     status: "offline"
//   },
//   {
//     id: 12,
//     kelas: "XII IPA 4",
//     rombel: "B",
//     nisn: "1122334456",
//     nama_siswa: "Rama Wijaya",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Pak Agus",
//     no_peserta_ujian: "20230123412",
//     token: "EFG123HIJ",
//     status: "online"
//   },
//   {
//     id: 13,
//     kelas: "XII IPS 3",
//     rombel: "A",
//     nisn: "2233445567",
//     nama_siswa: "Ayu Lestari",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Bu Anisa",
//     no_peserta_ujian: "20230123413",
//     token: "HIJ456KLM",
//     status: "soal terkirim"
//   },
//   {
//     id: 14,
//     kelas: "XII IPS 3",
//     rombel: "B",
//     nisn: "3344556678",
//     nama_siswa: "Dedi Prasetyo",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Pak Surya",
//     no_peserta_ujian: "20230123414",
//     token: "KLM789NOP",
//     status: "mengerjakan"
//   },
//   {
//     id: 15,
//     kelas: "XII IPS 4",
//     rombel: "A",
//     nisn: "4455667789",
//     nama_siswa: "Lina Mulyani",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Bu Irma",
//     no_peserta_ujian: "20230123415",
//     token: "NOP012QRS",
//     status: "selesai"
//   },
//   {
//     id: 16,
//     kelas: "XII IPS 4",
//     rombel: "B",
//     nisn: "5566778890",
//     nama_siswa: "Dewi Anggraini",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Pak Udin",
//     no_peserta_ujian: "20230123416",
//     token: "QRS345TUV",
//     status: "offline"
//   },
//   {
//     id: 17,
//     kelas: "XII IPA 5",
//     rombel: "A",
//     nisn: "6677889901",
//     nama_siswa: "Adi Wibowo",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Bu Fatimah",
//     no_peserta_ujian: "20230123417",
//     token: "TUV678WXY",
//     status: "online"
//   },
//   {
//     id: 18,
//     kelas: "XII IPA 5",
//     rombel: "B",
//     nisn: "7788990012",
//     nama_siswa: "Aulia Fitri",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Pak Teguh",
//     no_peserta_ujian: "20230123418",
//     token: "WXY901ZAB",
//     status: "soal terkirim"
//   },
//   {
//     id: 19,
//     kelas: "XII IPS 5",
//     rombel: "A",
//     nisn: "8899001123",
//     nama_siswa: "Fajar Sugiarto",
//     jenis_kelamin: "Laki-laki",
//     guru_pengampu: "Bu Hana",
//     no_peserta_ujian: "20230123419",
//     token: "ZAB234CDE",
//     status: "mengerjakan"
//   },
//   {
//     id: 20,
//     kelas: "XII IPS 5",
//     rombel: "B",
//     nisn: "9900112234",
//     nama_siswa: "Ria Putri",
//     jenis_kelamin: "Perempuan",
//     guru_pengampu: "Pak Ridwan",
//     no_peserta_ujian: "20230123420",
//     token: "CDE567FGH",
//     status: "selesai"
//   }
// ];

let search = window.location.search;
let params = new URLSearchParams(search);
let id = params.get('id');

const Monitoring = () => {

    const [studentsData, setStudentsData] = useState(null);

    useEffect(() => {
      getUjianDetail() 
    }, [])

    const getUjianDetail = async () => {
      // /ujian/detail-jawaban/peserta/20
      var res = await axiosInstance.get(`ujian/${id}`);
      console.log(res);
      var data = res.data.data;
      var tmp = []
      for(var i = 0; i < data.peserta.length; i++){
        tmp.push({
          id: data.peserta[i].id,
          kelas: data.peserta[i].detail.kelas,
          rombel: data.peserta[i].detail.rombel,
          nama_siswa: data.peserta[i].user.name,
          guru_pengampu: data.peserta[i].detail.wali_kelas,
          token: data.detail.token,
          status: data.peserta[i].status
        })
      }

      setStudentsData(tmp)
    }
    return (
        <Fragment>
      <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
      <Row>

        <Col lg={12}>
          <Card>
            <Card.Header>
              {/*<Card.Title>Recent Payments Queue</Card.Title>*/}
              <div className="col-xl-12">
                <div className="page-title flex-wrap">
                  <div className="input-group search-area mb-md-0 mb-3">
                    <input type="text" className="form-control" placeholder="Search here..."/>
                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                                                fill="#01A3FF"/>
                                        </svg>
                                    </Link></span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                <tr>
                  <th className="width80">
                    <strong>#</strong>
                  </th>
                  <th>
                    <strong>Kelas</strong>
                  </th>
                  <th>
                    <strong>Rombel</strong>
                  </th>
                  <th>
                    <strong>Nama Siswa</strong>
                  </th>
                  <th>
                    <strong>Guru Pengampu</strong>
                  </th>
                  <th>
                    <strong>Token</strong>
                  </th>
                  <th>
                    <strong>ID Peserta Ujian</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {studentsData?.map((student, index) => (
                    <tr key={index}>
                      <td>
                        <strong>{index}</strong>
                      </td>
                      <td>{student.kelas}</td>
                      <td>{student.rombel}</td>
                      <td>{student.nama_siswa}</td>
                      <td>{student.guru_pengampu}</td>
                      <td>{student.token}</td>
                      <td>{student.id}</td>
                      <td>
                        <Badge bg="" className="light badge-success">{student.status}</Badge>
                      </td>
                    </tr>
                ))}

                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
        </Fragment>
    );

}

export default Monitoring;