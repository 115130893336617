import React from 'react';
import { Table, Badge, Button } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

// Example data (user answers and correct answers)
const data = {
    answers: ['A', 'B', 'C', 'D', 'E', 'A', 'B', 'C', 'D', 'E'],
    correctAnswers: ['A', 'B', 'D', 'D', 'E', 'A', 'B', 'C', 'E', 'E']
};

// Function to return color based on whether the answer is correct or incorrect
const getStatusColor = (userAnswer, correctAnswer) => {
  return userAnswer === correctAnswer ? 'success' : 'danger';
};

const Hasil = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/pembahasan');
    };
    return (
        <Table bordered size="sm">
    <thead>
      <tr>
        <th className="table-primary">No</th>
        {data.answers.map((_, idx) => <th key={idx}>{idx + 1}</th>)}
      </tr>
    </thead>
    <tbody>
            <tr>
                <td className="table-primary">Jawaban</td>
                {data.answers.map((value, idx) => <td key={idx}><Badge bg={getStatusColor(value, data.correctAnswers[idx])}>
                    {value}
                </Badge></td>)}
            </tr>
            <tr><td className="table-primary">Jawaban Benar</td>
                {data.correctAnswers.map((value, idx) => <td key={idx}><Badge bg='success'>
                    {value}
                </Badge></td>)}
            </tr>
            <tr>
                <td className="table-primary">Pembahasan</td>
                {data.answers.map((value, idx) => <td key={idx}><Button onClick={handleClick} className="me-2" variant="light btn-square btn-xs">
        <i className="fa fa-eye" />
      </Button></td>)}
            </tr>
    </tbody>
  </Table>
    );
};

export default Hasil;
