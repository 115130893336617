var HOST = '';
if (process.env.NODE_ENV === 'development') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
}
if (process.env.NODE_ENV === 'staging') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
}
if (process.env.NODE_ENV === 'production') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
}

export const HOSTNAME = HOST;