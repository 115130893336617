import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Card, ListGroup, Row, Col, Badge, Button } from "react-bootstrap";

const data = [
    {
        key: 'Nama Ujian',
        value: 'Ujian Tryout Bhasa Indonesia'
    },
    {
        key: 'Durasi',
        value: '120 Menit'
    },
    {
        key: 'Jenis Soal',
        value: ['P', 'BS']
    }
]
const Pembahasan = () => {

    return (
        <Fragment>
            <PageTitle activeMenu="Pembahasan" motherMenu="Tryout" />
            <Card>
                <Card.Header>
                    <Card.Title>Preview Paket Ujian</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ListGroup as="ul" variant="flush">
                        {data.map((list, i) => (
                            <Fragment key={i}>
                                <ListGroup.Item as="li">
                                    <Row>
                                        <Col sm={3}>{list.key}</Col>
                                        {/*<Col sm={1}>:</Col>*/}
                                        <Col sm={8}>: {typeof list.value === 'object' ? (
                                            list.value.map((value, i) => (
                                                <Badge bg="" className="light badge-primary me-1">{value}</Badge>
                                            ))
                                        ) : list.value}</Col>
                                    </Row>
                                </ListGroup.Item>
                            </Fragment>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card name="default-tab" className="dz-card p-3">
                <Card.Header className="d-flex justify-content-between flex-wrap">
                    <div>
                        <Card.Title>Preview</Card.Title>
                    </div>
                    <div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col lg={12}>
                            <p><strong>1.</strong> Pernyataan di bawah ini yang benar terkait
                                dengan perbedaan antara novel dan cerpen adalah...</p>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col lg={12} className="justify-content-between flex-wrap">
                            <Badge as="a" href="" bg="secondary badge-circle">
                                A
                            </Badge>
                            <span>&nbsp; Bandung</span>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col lg={12} className="justify-content-between flex-wrap">
                            <Badge as="a" href="" bg="secondary badge-circle">
                                B
                            </Badge>
                            <span>&nbsp; Jakarta</span>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col lg={12} className="justify-content-between flex-wrap">
                            <Badge as="a" href="" bg="secondary badge-circle">
                                C
                            </Badge>
                            <span>&nbsp; Tegal</span>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col lg={12} className="justify-content-between flex-wrap">
                            <Badge as="a" href="" bg="secondary badge-circle">
                                D
                            </Badge>
                            <span>&nbsp; Semarang</span>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg={12} className="justify-content-between flex-wrap">
                            <Badge as="a" href="" bg="secondary badge-circle">
                                E
                            </Badge>
                            <span>&nbsp; Surabaya</span>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        Pembahasan :
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    );
}

export default Pembahasan;