import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isAuthenticated } from "./store/selectors/AuthSelectors";

export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}

export const mapStateToProps = (state) => {
    console.log('mapStateToProps sidebar', state);
    return {
      isAuthenticated: isAuthenticated(state),
      user: state.auth.auth,
    };
  }