import { Fragment } from "react";
import { Card, Col, Row, Dropdown, ListGroup, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const Cart = () => {
    const chackboxFun = (type) => {
        setTimeout(()=>{
          const motherChackBox = document.querySelector(".product_order_single");
          const chackbox = document.querySelectorAll(".product_order");
          for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
              if (motherChackBox.checked) {
                element.checked = true;
              } else {
                element.checked = false;
              }
            } else {
              if (!element.checked) {
                motherChackBox.checked = false;
                break;
              } else {
                motherChackBox.checked = true;
              }
            }
          }
        },100)
      };

      const listItem = [
        "Cras justo odio",
        "Dapibus ac facilisis in",
        "Morbi leo risus",
        "Porta ac consectetur ac",
        "Vestibulum at eros",
      ];

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Cart</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={9}>
                                    <div className="email-left-box dlab-scroll p-0">
                                        <div className="basic-list-group">
                                            <ListGroup as="ul" variant="flush">
                                                <ListGroup.Item
                                                    className="flex-column align-items-center"
                                                >
                                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                                        
                                                    <Row className="no-gutters">
                                                        <Col className="d-flex align-items-center">
                                                        <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                                                                    <input onClick={() => chackboxFun("all")} type="checkbox" className="form-check-input product_order_single" id="checkAll" required=""/>
                                                                    <label className="form-check-label" htmlFor="checkAll"></label>
                                                                </div>
                                                            <span className="font-weight-bold text-primary">Pilih Semua</span>
                                                        </Col>
                                                    </Row>
                                                        
                                                        <span className="font-weight-bold text-primary">Hapus</span>
                                                    </div>
                                                    
                                                </ListGroup.Item>
                                                {listItem.map((list, i) => (
                                                    <Fragment key={i}>
                                                        <ListGroup.Item
                                                            className="flex-column align-items-start"
                                                        >
                                                            <div className="d-flex w-100 justify-content-between">
                                                                
                                                            <Row className="no-gutters">
                                                                <Col md={6} className="d-flex">
                                                                <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                                                                    <input onClick={() => chackboxFun()} type="checkbox" className="form-check-input product_order" id="checkAll" required=""/>
                                                                    <label className="form-check-label" htmlFor="checkAll"></label>
                                                                </div>
                                                                <Card.Img
                                                                    src="https://via.placeholder.com/139x87" // Replace with the actual image URL
                                                                    alt="Mathematics"
                                                                />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <h5>Ujian Tryout Matematika Kelas XI</h5>
                                                                    <span className="mb-1">JK-1234567</span>
                                                                    <div>
                                                                    <Badge pill variant="success" className="mr-2">
                                                                        P
                                                                    </Badge>
                                                                    <Badge pill variant="primary">
                                                                        BS
                                                                    </Badge>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                                
                                                                <span>23 SC</span>
                                                            </div>
                                                            
                                                        </ListGroup.Item>
                                                    </Fragment>
                                                ))}
                                            </ListGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <h5>Ringkasan Belanja</h5>
                                    <Row className="mb-1">
                                        <Col lg={9}><span>Total Harga (3 Paket)</span></Col>
                                        <Col lg={3}><span>SC 155</span></Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col lg={9}><span>Biaya Admin</span></Col>
                                        <Col lg={3}><span>SC 15</span></Col>
                                    </Row>
                                    <div className="dropdown-divider"></div>
                                    <Row className="mb-3">
                                        <Col lg={9}><span>Total Belanja</span></Col>
                                        <Col lg={3}><span>SC 170</span></Col>
                                    </Row>
                                    <Button className="me-2 w-100" variant="primary btn-sm">Lakukan Pembayaran</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Cart;