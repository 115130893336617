import React, {Fragment, useEffect, useState} from "react";
import PageTitle from "../../layouts/PageTitle";
import {Badge, Button, Card, Col, Row} from "react-bootstrap";
import {Text} from "recharts";
import axiosInstance from "../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const UjianProcess = () => {
    const navigate = useNavigate();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');

    const [durasi, setDurasi] = useState(0);
    const [ujianContent, setUjianContent] = useState([]);
    const [indexSoal, setIndexSoal] = useState(0);
    const [answer, setAnswer] = useState([]);
    const [jumlahSoal, setJumlahSoal] = useState(0);
    useEffect(() => {
        if(localStorage.getItem('durasi') == null){
            getSoalData()
        }else{
            setDurasi(localStorage.getItem('durasi'))
            setUjianContent(JSON.parse(localStorage.getItem('detailUjian')))
            if(localStorage.getItem('jawaban') != null){
                setAnswer(JSON.parse(localStorage.getItem('jawaban')))
            }
            setJumlahSoal(localStorage.getItem('jumlahSoal'))
        }
        
    }, [])

    const getSoalData = async () => {
        try {
            const res = await axiosInstance.get(`test-online/siswa/ujian/content/${id}`);
            console.log(res);
            localStorage.setItem('detailUjian', JSON.stringify(res.data.data));
            localStorage.setItem('durasi', res.data.data.detail.durasi)
            var obj = {};
            var jumlah = 0;
            for(var i = 0; i < res.data.data.value.length; i++){
                
                obj[res.data.data.value[i].code] = res.data.data.value[i].value.jawaban
                if(res.data.data.value[i].type == "MPG"){
                    for(var a = 0; a < res.data.data.value[i].value.soal.length; a++){
                        obj[res.data.data.value[i].value.soal[a].code] = res.data.data.value[i].value.soal[a].jawaban;
                        jumlah++;
                    }
                }
                jumlah++;
            }


            localStorage.setItem('jawaban', JSON.stringify(obj))
            localStorage.setItem('jumlahSoal', jumlah)
            setAnswer(obj)
            setJumlahSoal(jumlah)
            setDurasi(res.data.data.detail.durasi)

            setUjianContent(res.data.data);

        } catch (error) {
            console.log(error);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: `${error.response.data.message}`,
            //     willClose: () => {
            //         navigate(-1);
            //     }
            // })
            // navigate(-1);
        }
    }
    const numbers = Array.from({ length: ujianContent?.value?.length }, (_, i) => i + 1);

    useEffect(() => {
        if (durasi > 0) {
          const timerId = setInterval(() => {
            setDurasi((prevSeconds) => prevSeconds - 1);
            localStorage.setItem('durasi', durasi - 1)
          }, 1000);
    
          return () => clearInterval(timerId); // Cleanup on unmount
        }
      }, [durasi]);
    
      const formatTime = (totalSeconds) => {
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const secs = String(totalSeconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
      };

    const handleAnswer = async (code, key) => {
        console.log("key : ", key)
        console.log("code : ", code)
        var jawaban = answer;
        console.log("jawaban list : ", jawaban)
        jawaban[code] = key;
        console.log("jawaban code : ", jawaban[code])
        console.log("jawaban : ", jawaban)
        setAnswer(jawaban);
        console.log("jawaban : ", jawaban)
        localStorage.setItem('jawaban', JSON.stringify(jawaban))

        var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, jawaban);

    }

    const handleKirimJawaban = async () => {
        var params = answer;

        var resKirim = await axiosInstance.post(`test-online/siswa/ujian/jawab/${id}`, params);

        var res = await axiosInstance.put(`test-online/siswa/ujian/finish/${id}`);

        localStorage.clear();

        Swal.fire({
            icon: 'success',
            title: 'Jawaban Berhasil dikirim',
            willClose: () => {
                navigate('/ujian/list');
            }
        })
    }
      
    return (
        <Fragment>
            <PageTitle activeMenu="ujian" motherMenu="ujian"/>
            <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
                <Row>
                    <Col xl={12}>
                        <Card name="default-tab" className="dz-card p-3">
                            <Card.Header className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <Card.Title>{ ujianContent?.detail?.mata_pelajaran }</Card.Title>
                                </div>
                                <div>
                                    <Card.Text>Waktu Tersisa : {formatTime(durasi)}</Card.Text>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {ujianContent.value && (ujianContent.value[indexSoal].type)}
                                <Row lg={12}>
                                    {ujianContent.value && (ujianContent.value[indexSoal].type != "MPG" && ujianContent.value[indexSoal].type != "UR") && <Col lg={8}> 
                                        <div className="email-left-box dlab-scroll  pt-3" style={{height: '100%'}}>
                                            <Row className="mb-2">
                                                <Col lg={12}>
                                                    <p><strong>{indexSoal + 1}.</strong> {ujianContent?.value[indexSoal].value.pertanyaan}</p>
                                                </Col>
                                            </Row>
                                            {Object.keys(ujianContent.value[indexSoal].value.pilihan).map((key, index)=>(
                                                <Row className="mb-1" key={index}>
                                                    <Col lg={12} className="justify-content-between flex-wrap">
                                                        <Badge onClick={() => handleAnswer(ujianContent.value[indexSoal].code, key)} as="a" bg={ (answer[ujianContent.value[indexSoal].code] == key ? "success" : "secondary") + " badge-circle"}>
                                                            {key}
                                                        </Badge>
                                                        <span>&nbsp; {ujianContent.value[indexSoal].value.pilihan[key]}</span>
                                                    </Col>
                                                </Row>
                                            ))}
                                            
                                        </div>
                                    </Col>}
                                    {ujianContent.value && (ujianContent.value[indexSoal].type == "UR") && <Col lg={8}> 
                                        <div className="email-left-box dlab-scroll  pt-3" style={{height: '100%'}}>
                                            <Row className="mb-2">
                                                <Col lg={12}>
                                                    <p><strong>{indexSoal + 1}.</strong> {ujianContent?.value[indexSoal].value.pertanyaan}</p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col lg={12} className="justify-content-between flex-wrap">
                                                <textarea
                                                    name=""
                                                    className="form-control"
                                                    id=""
                                                    // value={description}
                                                    onChange={(e) => handleAnswer(ujianContent.value[indexSoal].code, e.target.value)}
                                                    ></textarea>
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                    </Col>}
                                    {ujianContent.value && (ujianContent.value[indexSoal].type == "MPG") && <Col lg={8}> 
                                        <div className="email-left-box dlab-scroll  pt-3" style={{height: '100%'}}>
                                            <Row className="mb-2">
                                                <Col lg={12}>
                                                    <p>{ujianContent?.value[indexSoal].value.content}</p>
                                                </Col>
                                            </Row>
                                            {ujianContent?.value[indexSoal].value.soal.map((key, index)=>(
                                                <div className="mt-3">
                                                    <Row className="mb-2">
                                                        <Col lg={12}>
                                                            <p><strong>{indexSoal + 1 + index}.</strong> {ujianContent?.value[indexSoal].value.soal[index].pertanyaan}</p>
                                                        </Col>
                                                    </Row>
                                                    {Object.keys(ujianContent.value[indexSoal].value.soal[index].pilihan).map((key, indeks)=>(
                                                        <Row className="mb-1" key={indeks}>
                                                            <Col lg={12} className="justify-content-between flex-wrap">
                                                                <Badge onClick={() => handleAnswer(ujianContent.value[indexSoal].value.soal[index].code, key)} as="a" bg={ (answer[ujianContent.value[indexSoal].value.soal[index].code] == key ? "success" : "secondary") + " badge-circle"}>
                                                                    {key}
                                                                </Badge>
                                                                <span>&nbsp; {ujianContent.value[indexSoal].value.soal[index].pilihan[key]}</span>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            ))}                                            
                                        </div>
                                    </Col>}
                                    <Col lg={4} className="">
                                    <div className="email-right-box mr-1">
                                        <Row>
                                        {answer && Object.keys(answer).map((key, index)=>(
                                           
                                                <Col lg={2} key={index  }>
                                                <Button onClick={() => setIndexSoal(index)} style={{"width": "50px"}} className="m-1" variant={ (answer[key] == null ? "primary" : answer[key] == 'ragu' ? "warning" : "success") + " btn-sm"}>
                                                    {index + 1}
                                                </Button>
                                                </Col>
                                            
                                        ))}
                                        </Row>

                                    </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col>
                                    {indexSoal > 0 && <Button className="me-2" variant="primary btn-md" onClick={() => {setIndexSoal(indexSoal - 1)}}>
                                    Sebelumnya
                                </Button>}
                                <Button className="me-2" variant="warning btn-md" onClick={() => handleAnswer(ujianContent.value[indexSoal].code, 'ragu')}>
                                    Ragu-Ragu
                                </Button>
                                {indexSoal < jumlahSoal - 1 && <Button className="me-2" variant="primary btn-md" onClick={() => {setIndexSoal(indexSoal + 1)}}>
                                    Selanjutnya
                                </Button>}
                                    </Col>
                                    <Col>
                                    <Button className="me-2" variant="success btn-md" onClick={() => handleKirimJawaban()}>
                                    Kirim Jawaban
                                </Button>
                                    </Col>
                                </Row>
                                

                                
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Fragment>
);
}

export  default UjianProcess;