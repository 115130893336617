import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const Petunjuk = () => {
    const listItem = [
        {
            key: "Waktu Ujian",
            value: "60 menit"
        },
        {
            key: "Jenis Soal",
            value: "Pilihan Ganda"
        },
        {
            key: "Jumlah Soal",
            value: "60 Soal"
        }
    ]

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/ujian/process');
    };

    return (
        <Fragment>
            <PageTitle activeMenu="Petunjuk" motherMenu="Tryout" />
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Petunjuk Pengerjaan Tryout</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup as="ul" variant="flush">
                                {listItem.map((list, i) => (
                                    <Fragment key={i}>
                                    <ListGroup.Item
                                    className="flex-column align-items-start"
                                    >
                                    <div className="d-flex w-100 justify-content-between">
                                        <span className="mb-3">
                                        {list.key}
                                        </span>
                                        <span>{list.value}</span>
                                    </div>
                                    </ListGroup.Item>
                                    </Fragment>
                                ))}
                            </ListGroup>
                            <h5 className="mt-3">Panduan Pengerjaan</h5>
                            <ListGroup as="ul" variant="flush">
                                {listItem.map((list, i) => (
                                    <Fragment key={i}>
                                    <ListGroup.Item
                                    className="flex-column align-items-start"
                                    >
                                    <div className="d-flex w-100 justify-content-between">
                                        <span className="mb-3">
                                        {list.key}
                                        </span>
                                        <span>{list.value}</span>
                                    </div>
                                    </ListGroup.Item>
                                    </Fragment>
                                ))}
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer className=" d-sm-flex justify-content-end align-items-center">
                            <Button className="me-2" variant="primary btn-sm" onClick={handleClick}>
                                Mulai Tryout
                                &nbsp;<i className="bi bi-skip-end-fill"></i>
                            </Button>                            
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

export default Petunjuk;