import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";

const Bursa = () => {
    const numbers = Array.from({ length: 30 }, (_, i) => i + 1);

    return (
        <Fragment>
            <PageTitle activeMenu="Bursa" motherMenu="Tryout" />
            <div className="row">
                <div className="col-xl-12">
                    <div className="page-titles">
                        <div className="d-flex align-items-center flex-wrap ">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control form-control-sm" placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                                                fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Row>
                        {numbers.map((number, index) => (
                           <Col lg={3} key={index}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Card.Text>23 - 39989065</Card.Text>
                                        </Col>
                                        <Col lg={6}>
                                            <Card.Text>23 - 39989065</Card.Text>
                                        </Col>
                                    </Row>
                                    <h5>Uji Paket Matematika</h5>
                                    <p>Dona Wariska</p>
                                    <Row>
                                        <Col lg={6} className="d-flex justify-content-evenly flex-wrap">
                                            <Button variant="success" size="xs">P</Button>
                                            <Button variant="primary" size="xs">BS</Button>
                                        </Col>
                                        <Col lg={6} className="d-flex justify-content-end flex-wrap">
                                            <p>23 SC</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col lg={8} className="d-flex">
                                            <Row>
                                                <Col className="p-0">
                                                    <i className="bi bi-clock"></i>
                                                    <span style={{ color: "#01A3FF", fontSize: "12px" }}>&nbsp;30 Menit</span>
                                                </Col>
                                                <Col className="m-0 p-0">
                                                    <i className="bi bi-clock"></i>
                                                    <span style={{ color: "#01A3FF", fontSize: "12px" }}>&nbsp;10 Soal</span>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col lg={4} className="d-flex">
                                            <Button variant="primary" size="xxs"><i className="fa fa-shopping-cart"></i></Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col> 
                        ))}
                    </Row>
                </div>
            </div>
        </Fragment>
    );
}

export default Bursa;